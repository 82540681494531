import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientUiServiceAreaToTermsOfServiceEdge } from '@app/registration/graph/edges/__generated__/service-area-to-terms-of-service-edge-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreaToTermsOfServiceEdgeGraphQLService extends Query<PatientUiServiceAreaToTermsOfServiceEdge> {
  document = gql`
    query PatientUIServiceAreaToTermsOfServiceEdge {
      patient {
        id
        hasUnsignedLegalDocuments
      }
    }
  `;
}
