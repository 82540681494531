<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="OmguiInteractiveCardVariant.Banner">
    <div class="card w-100 h-100 card-banner-variant">
      <div class="banner card-header border-0 d-flex flex-row align-items-start p-3 p-sm-4">
        <ng-container *ngIf="bannerPicture">
          <img class="pe-3" src="{{ bannerPicture }}" />
        </ng-container>
        <h6>{{ title }}</h6>
      </div>

      <div class="card-body">
        <div class="card-text list-inline ps-3 pb-0">
          <ng-container [ngTemplateOutlet]="bodyContent"></ng-container>
        </div>
      </div>

      <div class="card-footer border-0 bg-transparent pb-4">
        <omgui-button
          variant="yellow-primary"
          size="fill-parent"
          data-cy="card-button"
          [newTab]="buttonParams.newTab ?? false"
          [internalLink]="buttonParams.internalLink"
          [externalLink]="buttonParams.externalLink"
          [queryParams]="buttonParams.queryParams"
          [trackLink]="buttonParams.trackLink"
        >
          <ng-container [ngTemplateOutlet]="selectButtonContent"></ng-container>
        </omgui-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="card w-100 h-100">
      <div class="card-body">
        <h6 class="card-title">
          {{ title }}
        </h6>

        <div class="card-text pt-2 ps-3 pb-0">
          <ng-container [ngTemplateOutlet]="bodyContent"></ng-container>
        </div>
      </div>

      <div class="card-footer border-0 bg-transparent pb-4">
        <omgui-button
          variant="secondary"
          size="fill-parent"
          data-cy="card-button"
          [newTab]="buttonParams.newTab ?? false"
          [internalLink]="buttonParams.internalLink"
          [externalLink]="buttonParams.externalLink"
          [queryParams]="buttonParams.queryParams"
          [trackLink]="buttonParams.trackLink"
        >
          <ng-container [ngTemplateOutlet]="selectButtonContent"></ng-container>
        </omgui-button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #bodyContent>
  <ng-content></ng-content>
</ng-template>

<ng-template #selectButtonContent>
  <ng-content select="[buttonContent]"></ng-content>
</ng-template>
