import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';

import { MembershipAction, MembershipService } from '@app/core/membership.service';
import { MembershipUpdateToCompleteRegistrationEdge } from '@app/registration/graph/edges/membership-update-to-complete-registration.edge';
import { GraphNavigationService } from '@app/registration/graph/graph-navigation.service';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { ClaimCodeValidationError, PrepaidEnrollmentService } from '@app/registration/prepaid-enrollment.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateNode extends RegistrationNode {
  constructor(
    private membershipService: MembershipService,
    private graphNavigationService: GraphNavigationService,
    private membershipUpdateToCompleteRegistrationEdge: MembershipUpdateToCompleteRegistrationEdge,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [membershipUpdateToCompleteRegistrationEdge];
    this.isAsync = true;
  }

  execute$({ claimCode }: { claimCode?: string } = {}): Observable<void> {
    return this.membershipService
      .createOrRenewConsumerOrAmazonMembership({
        claimCode,
        membershipAction: MembershipAction.New,
      })
      .pipe(
        map(() => undefined),
        catchError(error =>
          this.prepaidEnrollmentService
            .getErrorRoute$({
              error: new ClaimCodeValidationError(error),
              source: 'Login with Amazon',
              claimCode,
            })
            .pipe(
              map(errorUrl => {
                this.router.navigateByUrl(errorUrl);
              }),
            ),
        ),
      );
  }
}
